import { Fragment, useEffect, useState } from 'react'
import classes from './NumbersPopup.module.css'
import { useDispatch, useSelector } from 'react-redux'
import useHttp from '../../../../hooks/use-http'
import { uiActions } from '../../../../store/ui-slice'

const NumbersPopup = (props) => {
  const {sendRequest: sendMessage, isLoading} = useHttp()
  const dispatch = useDispatch()

  const dashboards = useSelector((state) => state.hub.dashboards)
  const loadedAllDashboards = useSelector((state) => state.hub.loadedAllDashboards)

  const [numbers, setNumbers] = useState([])
  const [showTemplates, setShowTemplates] = useState()
  const [templates, setTemplates] = useState([])
  const [showSendEdit, setShowSendEdit] = useState()

  useEffect(() => {
    let numbers = []
    let nums
    if(props.phoneMobile){
      nums = <div id={`${!props.message && 'Call'} ${props.dashboardSettingId} ${props.id} PhoneMobile ${props.phoneMobile.replace(/[^0-9]/g,"")}`}>
                  <p className={classes.name} onClick={() => clickNumberHandler('PhoneMobile', props.phoneMobile)}>Mobile</p>
                  <p className={classes.number} onClick={() => clickNumberHandler('PhoneMobile', props.phoneMobile)}>{props.phoneMobile}</p>
                  {showTemplates === 'PhoneMobile' && <div className={classes['templates-popup']} id={`${props.dashboardSettingId} ${props.id} PhoneMobile ${props.phoneMobile.replace(/[^0-9]/g,"")}`}>{templates}</div>}
              </div>
      numbers = [...numbers, nums]
    }
    if(props.phoneSpouse){
      nums = <div id={`${!props.message && 'Call'} ${props.dashboardSettingId} ${props.id} PhoneSpouse ${props.phoneSpouse.replace(/[^0-9]/g,"")}`}>
                  <p className={classes.name} onClick={() => clickNumberHandler('PhoneSpouse', props.phoneSpouse)}>Spouse Mobile</p>
                  <p className={classes.number} onClick={() => clickNumberHandler('PhoneSpouse', props.phoneSpouse)}>{props.phoneSpouse}</p>
                  {showTemplates === 'PhoneSpouse' && <div className={classes['templates-popup']} id={`${!props.message && 'Call'} ${props.dashboardSettingId} ${props.id} PhoneSpouse ${props.phoneSpouse.replace(/[^0-9]/g,"")}`}>{templates}</div>}
              </div>
      numbers = [...numbers, nums]
    }
    if(props.email){
      nums = <div><p className={classes.name} onClick={() => clickNumberHandler('Email', props.email)}>Email</p>
                  <p className={classes.number} onClick={() => clickNumberHandler('Email', props.email)}>{props.email}</p>
                  {showTemplates === 'Email' && <div className={classes['templates-popup']} id={`${props.dashboardSettingId} ${props.id} Email ${props.email}`}>{templates}</div>}
                  </div>
                  numbers = [...numbers, nums]
    }
    if(props.phoneHome){
      nums = <div id={`${!props.message && 'Call'} ${props.dashboardSettingId} ${props.id} PhoneHome ${props.phoneHome.replace(/[^0-9]/g,"")}`}>
                  <p className={classes.name} onClick={() => clickNumberHandler('Home', props.phoneHome)}>Home</p>
                  <p className={classes.number} onClick={() => clickNumberHandler('Home', props.phoneHome)} id={`${!props.message && 'Call'} ${props.dashboardSettingId} ${props.id} PhoneHome ${props.phoneHome.replace(/[^0-9]/g,"")}`}>{props.phoneHome}</p>
                  </div>
      numbers = [...numbers, nums]
    }
  setNumbers(numbers)    
  }, [props, showTemplates, templates])

  useEffect(()=>{
    if(dashboards.length > 0 && loadedAllDashboards){
      let dashboard = dashboards.find(d=> d.DashboardSettingID === props.dashboardSettingId)
      if(dashboard){
        const tempTemplates = [...dashboard.SmsTemplates, {TemplateID: 0, Name: 'New Template'}]
        let t = tempTemplates.map((template, i) => {
          return <div key={template.TemplateID} className={`${classes['number-item']} ${(i + 1) % 2 === 0 && classes.second} ${template.TemplateID === 0 && classes.hide}`} onClick={() => template.TemplateID > 0 ? selectTemplateHandler(template.TemplateID) : props.onClose()} id={template.TemplateID === 0 ? 'New-Template' : template.TemplateID}>
            <p className={classes.name}>{template.Name}</p>
            {showSendEdit === template.TemplateID && <div className={classes['send-popup']} id='Send-Edit'>
              <div className={classes['number-item']} onClick={() => sendMessageHandler(template.TemplateID)}><p className={`${classes.name} `}>Send</p></div>
              <div className={`${classes['number-item']} ${classes.second} ${classes.hide}`} id='Edit-Template' onClick={props.onClose}><p className={classes.name}>Edit</p></div>
              </div>}
          </div>
        })
        setTemplates(t)
      }
      }
  },[dashboards, showSendEdit, loadedAllDashboards])

  const clickNumberHandler = (type, number) => {
    if(props.message){
      if(type === 'PhoneMobile' || type === 'PhoneSpouse' || type === 'Email'){
        setShowTemplates(type)
      }
    }
    else if(window.location.href.indexOf("-app") === -1){
      const num = number.replace(/\D/g, '')
      window.location.href = `tel:+1${num}`;    
    }
  }

  const selectTemplateHandler = (id) => {
    setShowSendEdit(id)
  }

  const sendMessageHandler = (templateId) => {
    sendMessage({url: `api/sendMessage?dashboardId=${props.dashboardSettingId}&id=${props.id}&contactType=${showTemplates}&templateId=${templateId}`}, handleMessageResponse)

  }

  const handleMessageResponse = (data) => {
    if(data && data !== ''){
      dispatch(uiActions.showSidePopup({status: 'error', header: 'Error sending message', message: data }))
    }
    else if(data === ''){
      dispatch(uiActions.showSidePopup({status: 'success', header: 'Successfully sent message'}))
    }
    props.onClose()
  }
  

    return <Fragment>{numbers.length > 0 && <div className={`${classes.popup} ${isLoading && classes.loading}`}>
      {numbers && numbers.map((n, i) => {
            return <div key={Math.random()} 
                      className={`${classes['number-item']} ${(i + 1) % 2 === 0 && classes.second}`}>
                {n}
            </div>
        })}
    </div>}
    </Fragment>
}

export default NumbersPopup