import { Fragment } from 'react'
import RemindersDetails from '../../components/Donors/DonorsReminders/ReminderDetails'

const ReminderDetails = () => {

    return (
        <Fragment>
            <RemindersDetails/>
        </Fragment>
    )
}

export default ReminderDetails