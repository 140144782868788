import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    locations: [],
    locationRequired: false,
    isLoading: false,
}

const locationsSlice = createSlice({
    name: "locations",
    initialState,
    reducers: {
        loadLocations(state, action) {
            state.locations = action.payload.locations;
            if(action.payload.locations.length  > 0){
                state.locationRequired = action.payload.locations[0].Required
            }
        },
        setIsLoading(state, action) {
            state.isLoading = action.payload
        },
        resetState:() => initialState,
    },
});

export const locationsActions = locationsSlice.actions;

export default locationsSlice;
