
import classes from './AddTask.module.css'
import ModelNew from '../../../UI/Modals/ModalNew'
import { useNavigate, useSearchParams } from 'react-router-dom'
import TextField from '../../../UI/Inputs/TextField'
import DateInput from '../../../UI/Inputs/DateInput'
import ButtonMOut from '../../../UI/Buttons/Buttons-Outline/ButtonMOut'
import ButtonM from '../../../UI/Buttons/Buttons/ButtonM'
import { useEffect, useState } from 'react'
import { CurrentDateForDateTimeInput } from '../../../../functions/Dates/datesForDateInput'
import CustomSelectFreeText from '../../../UI/Dropdowns/CustomSelectFreeText'
import DonorSearchBox from '../../../UI/SearchBoxs/DonorSearchBox/DonorSearchBox'
import { useDispatch, useSelector } from 'react-redux'
import { fetchDashboards, fetchListForNewTask, fetchReminderTypes, fetchReminderNames } from '../../../../store/hub-actions'
import PinkTextButton from '../../../UI/Buttons/TextButtons/PinkTextButton'
import useHttp from '../../../../hooks/use-http'
import { uiActions } from '../../../../store/ui-slice'
import Input from '../../../UI/Inputs/Input'
import { getAddMessageBody } from '../../../../functions/getMessageBody'
import CustomSelect from '../../../UI/Dropdowns/CustomSelect'
import Checkbox from '../../../UI/Checkboxs/Checkbox'
import ShulsList from '../../../Payments/PaymentsDropdowns/ShulsList'

const AddTask = (props) => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {sendRequest: saveTask, isLoading} = useHttp()
    
    const [searchParams] = useSearchParams()

    const types = useSelector((state) => state.hub.reminderTypes)
    const names = useSelector((state) => state.hub.reminderNames)
    const list = useSelector((state) => state.hub.listForNewTask)
    const dashboards = useSelector((state) => state.hub.dashboards)
    const campaignList = useSelector((state) => state.campaigns.campaigns)
    const selectedDonor = useSelector((state) => state.donors.selectedDonor)

    const [listObj, setListObj] = useState([])
    const [nameInvalid, setNameInvalid] = useState()
    const [selectedObj, setSelectedObj] = useState(null)
    const [selectedIdInvalid, setSelectedIdInvalid] = useState()
    const [description, setDescription] = useState('')
    const [campaigns, setCampaigns] = useState([])
    const [reminderForSave, setReminderForSave] = useState({})

    useEffect(()=>{
        if(types.length === 0){
            dispatch(fetchReminderTypes())
        }
        if(names.length === 0 && (!searchParams.get('dashboard') || searchParams.get('id'))){ 
            dispatch(fetchReminderNames())
        }
        let task = {
            ID: null,
            DashboardID: searchParams.get('dashboard') ? parseInt(searchParams.get('dashboard')) : props.donor ? -1 : 0, //if coming from donor it's always donor even if working with is setup to a different type
            Type: '',
            Description: '',
            Name: '',
            CampaignID: null,
            Date: CurrentDateForDateTimeInput(),
            SendEmail: false,
            IsFinancial: false
        }
        setReminderForSave(task)
    },[])

    useEffect(()=>{
        if(!props.donor){
            dispatch(fetchListForNewTask(searchParams.get('dashboard') ? parseInt(searchParams.get('dashboard')) : 0))
            setSelectedObj(null)
        }
    },[props.donor])

    useEffect(()=>{
        if(campaignList.length > 0){
            let campaigns = campaignList.map(campaign => {
                return {value: campaign.CampaignID, name: campaign.Name}
            })
            campaigns = [{ value: 0, name: '-'}, ...campaigns]
            setCampaigns(campaigns)
            var dCampaign = campaignList.find(c => c.Default)
            if(dCampaign){
                setReminderForSave((prevState) => ({...prevState, CampaignID: dCampaign.CampaignID}))
            }
        }
    },[campaignList])

    useEffect(()=>{
        let id
        if(searchParams.get('dashboard') && dashboards.length > 0){
            id = parseInt(searchParams.get('dashboard'))
            if(id && !searchParams.get('id')){
                let d = dashboards.find(da => da.DashboardSettingID === id)
                if(d){
                    setReminderForSave((prevState) => ({...prevState, Name: d.Name}))
                }
            }
           
        }
    },[dashboards, searchParams])

    useEffect(() => {
        if(list && !props.donor){
            let lst = []
            if(list.Donors){
                lst = list.Donors.map(donor => {
                    return {value: donor.DonorsID, type: 'Donor', name: donor.FirstName + " " + donor.LastName, phoneHome: donor.PhoneHome, phoneMobile: donor.PhoneMobile, email: donor.Email, address: {Address: donor.Address, City:  donor.City, State: donor.State, Zip: donor.Zip}}
                })
            } 
            else if(list.Parents){
                lst = list.Parents.map(parent => {
                    return {value: parent.ParentID, type: 'Parent', name: parent.FirstName + " " + parent.LastName, phoneHome: parent.PhoneHome, phoneMobile: parent.PhoneMobile, email: parent.Email, address: {Address: parent.Address, City:  parent.City, State: parent.State, Zip: parent.Zip}}
                })
            }
            else if(list.Students){
                lst = list.Students.map(student => {
                    return {value: student.StudentID, type: 'Student', name: student.FirstName + " " + student.LastName, phoneHome: student.PhoneHome, phoneMobile: student.PhoneMobile, email: student.Email, address: {Address: student.Address, City:  student.City, State: student.State, Zip: student.Zip}}
                })
            }
            setListObj(lst)
        }
    },[list, props.donor])

    useEffect(()=>{
        if(props.donor && selectedDonor.DonorsID){
            setSelectedObj({value: selectedDonor.DonorsID, type: 'Donor', name: selectedDonor.FirstName + " " + selectedDonor.LastName, phoneHome: selectedDonor.PhoneHome, phoneMobile: selectedDonor.PhoneMobile, email: selectedDonor.Email, address: {Address: selectedDonor.Address, City:  selectedDonor.City, State: selectedDonor.State, Zip: selectedDonor.Zip}})
            setReminderForSave((prevState) => ({...prevState, ID: selectedDonor.DonorsID}))

        }
    },[props.donor, selectedDonor])

    useEffect(() => {
        if(listObj.length > 0){
            if(searchParams.get('id')){
                changeObjHandler(parseInt(searchParams.get('id')))
            }
        }
    },[listObj, searchParams])

    const closeNewTaskHandler = () => {
        if(props.donor){
            props.onClose()
        }
        else{
            dispatch(fetchDashboards(true))
            navigate(-1)
            }    }

   

    const changeObjHandler = (id) => {
        setReminderForSave((prevState) => ({...prevState, ID: id}))
        setSelectedIdInvalid(false)
        const obj = listObj.find(p => p.value === id)
        if(obj){
            setSelectedObj(obj)
        }
        else{
            setSelectedObj(null)
        }
    }

    const saveTaskHandler = () => {
        if(!reminderForSave.ID){
            setSelectedIdInvalid(true)
            return
        }
        if(!reminderForSave.Name || reminderForSave.Name === ''){
            setNameInvalid(true)
            return
        }
        saveTask({url: 'api/saveTask', method: 'POST', body: reminderForSave, headers: {
            Accept: "application/json",
            "content-type": "application/json",
          },}, handleTaskResponse)
    }

    const handleTaskResponse = (data) => {
        if(data.ActionSuccessful){
            dispatch(uiActions.showSidePopup({status: 'success', header: getAddMessageBody(props.donor ? 'reminder' : 'task')}))
            closeNewTaskHandler()
        }
        else{
            dispatch(uiActions.showSidePopup({status: 'error', header: getAddMessageBody(props.donor ? 'reminder' : 'task', false), message: data.ActionResultMessage}))
        }
    }

    //#region change inputs 
    const changeTypeHandler = (value) => {
        setReminderForSave((prevState) => ({...prevState, Type: value}))
    }

    const changeNameHandler = (value) => {
        setReminderForSave((prevState) => ({...prevState, Name: value}))
        if(value && value !== ''){
            setNameInvalid(false)
        }
    }
    const changeCampaignHandler = (id) => {
        setReminderForSave((prevState) => ({...prevState, CampaignID: id}))
    }

    const changeDateHandler = (value) => {
        setReminderForSave((prevState) => ({...prevState, Date: value}))
    }

    const changeSendEmailHandler = () => {
        setReminderForSave((prevState) => ({...prevState, SendEmail: !prevState.SendEmail}))
    }

    const changeIsFinancialHandler = () => {
        setReminderForSave((prevState) => ({...prevState, IsFinancial: !prevState.IsFinancial}))
    }

    const changeShulHandler = (id) => {
        setReminderForSave((prevState) => ({...prevState, ShulID: id}))
    }

    //#endregion
    return (
        <ModelNew title={props.donor ? 'Reminder' : 'Task'} onClose={closeNewTaskHandler} className={classes.white} modelClassName={classes.model} backdropClassName={classes.backdrop}>
            <div className={classes.dropdowns}>
            {(!searchParams.get('dashboard') || searchParams.get('id')) && <CustomSelectFreeText options={names} label='Name' required onChange={changeNameHandler} className={classes.name} selectedValue={reminderForSave.Name} invalid={nameInvalid}/>}
                {searchParams.get('dashboard') && !searchParams.get('id') && <Input label='Name' readOnly value={reminderForSave.Name}  className={classes.name} />}
                <DateInput datetime label='Due Date' value={reminderForSave.Date} onChange={changeDateHandler} className={classes.date}/>
            </div>
            <div className={classes.dropdowns}>
            <CustomSelectFreeText selectedValue={reminderForSave.Type} options={types} label='Type' onChange={changeTypeHandler}className={classes.type} />
                {campaigns && campaigns.length > 0 && <CustomSelect options={campaigns} label='Campaign' onChange={changeCampaignHandler} selectedValue={reminderForSave.CampaignID} className={classes.campaign}/>}
                </div>
            <div>
            {selectedIdInvalid && listObj.length > 0 && <p className={classes.invalid}>Select {listObj[0].type}</p>}
            {!selectedObj && !props.donor && listObj.length > 0 && <DonorSearchBox list={listObj} placeholder={`Search ${listObj[0].type}`} onSelect={changeObjHandler} className={classes['donor-search']}/>}
            {selectedObj && <div className={classes['parent-wrapper']}>
                <p className={classes['parent-label']}>{selectedObj.type}</p>
                <div className={classes['connected-parent']}>
                <p className={classes.parentid}>{selectedObj.value}</p>
                <p className={classes['parent-name']}>{selectedObj.name}</p>
                {!props.donor && <PinkTextButton className={classes['remove-parent']} onClick={() => changeObjHandler(null)}>Remove</PinkTextButton>}
                </div></div>}
            </div>
            <TextField label='Description' value={description} onChange={(v) => setDescription(v)}/>
                    <ShulsList onChange={changeShulHandler} onChangeInput={()=> {}} shulID={reminderForSave.ShulID} className={classes.shul}/>
                    <div className={classes.checkboxes}>
                        <Checkbox id='sendEmail' label='Send Email' checked={reminderForSave.SendEmail} onChange={changeSendEmailHandler}/>
                        <Checkbox id='isFinancial' label='Financial Reminder' checked={reminderForSave.IsFinancial} onChange={changeIsFinancialHandler}/>
                    </div>
            <div className={classes.buttons}>
                <ButtonMOut onClick={closeNewTaskHandler}>Cancel</ButtonMOut>
                <ButtonM onClick={saveTaskHandler} loading={isLoading}>Save</ButtonM>
            </div>
        </ModelNew>
    )
}

export default AddTask