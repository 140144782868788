import { useEffect, useState } from "react"
import classes from './TaskQueries.module.css'
import formatFieldByFieldName from "../../../../functions/formatFieldByFieldName"

const TaskQueries = (props) => {
    const [queries, setQueries] = useState([])
    const [selectedQuery, setSelectedQuery] = useState()
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc'}) // 'asc' for ascending, 'desc' for descending});

    useEffect(()=>{
        if(props.queries.length > 0){
            let q = [...props.queries]
            q.forEach(e => {
                try{
                    e.Table = JSON.parse(e.Table)
                }
                catch{ }
            }); 
            setQueries(q)
            if(q.length > 0){
                if(sessionStorage.getItem('selectedTaskQuery') && q.find(i => i.Caption === sessionStorage.getItem('selectedTaskQuery'))){
                    setSelectedQuery(q[q.findIndex(i => i.Caption === sessionStorage.getItem('selectedTaskQuery'))])
                }
                else{
                    setSelectedQuery(q[0])
                }
            }
        }
    },[props.queries])

    const changeSelectedQuery = (query) => {
        setSelectedQuery(query)
        setSortConfig({key: null, direction: 'asc'})
        sessionStorage.setItem('selectedTaskQuery', query.Caption)
    }
    const changeSortHandler = (key) => {
        let direction = 'asc';
        console.log(direction + key)

        // Check if the same column is clicked to toggle direction
        if (sortConfig.key === key) {
          direction = sortConfig.direction === 'asc' ? 'desc' : 'asc';
        }
        // Check if the property is numeric
        const isNumeric = !isNaN(parseFloat(selectedQuery.Table[0][key])) && typeof selectedQuery.Table[0][key] === 'number';
      
        // Sort the Table array based on the property
        const sortedTable = [...selectedQuery.Table].sort((a, b) => {
          if (isNumeric) {
            // If it's numeric, sort numerically
            return direction === 'asc' ?  a[key] - b[key] : b[key] - a[key];
          } else {
            // If it's not numeric, sort lexicographically (string comparison)
            if ((a[key] || '') < (b[key] || '')) {
              return direction === 'asc' ? -1 : 1;
            }
            if ((a[key] || '') > (b[key] || '')) {
              return direction === 'asc' ? 1 : -1;
            }
            return 0;
          }
        });
        console.log(sortedTable)
        setSortConfig({ key, direction })
        setSelectedQuery(prevState => ({...prevState, Table: sortedTable}))
    }
    return (
        <div className={classes.queries}>
            <div className={classes.captions}>
            {selectedQuery && queries.map(q => {
               return <p key={q.Caption} className={`${classes.caption} ${selectedQuery.Caption === q.Caption && classes.active}`} onClick={() => changeSelectedQuery(q)}>{q.Caption}</p>
            })}
            </div>
            {selectedQuery && selectedQuery.Table.length > 0 && <div className={classes.headers}>
            {Object.entries(selectedQuery.Table[0]).map(([_key, value]) => {
                return <p className={classes.header} key={_key} onClick={() => changeSortHandler(_key)}>{_key}</p>
            })}
                </div>}
                <div className={classes.rows}>
            {selectedQuery && selectedQuery.Table.map((r, i) => {
                return <div className={classes.row} key={i}>{Object.entries(r).map(([_key, value]) => {
                    return <div key={_key} className={classes.row}>
                        <p className={classes['row-value']}>{formatFieldByFieldName(_key, value ? value.toString() : '')}</p>
                    </div>
                })}
                </div>
            })}
            </div>
        </div>
    )
}

export default TaskQueries