import { useSelector } from 'react-redux'
import React,{ useState, useEffect, Fragment } from 'react'

import CustomSelect from '../../UI/Dropdowns/CustomSelect'


const ShulsList = (props) => {

    //#region hooks
    const [shuls, setShuls] = useState()

    const shulList = useSelector((state) => state.shuls.shuls)

    useEffect(() => {

        if (shulList.length !== 0) {
            let initialShul
            if (props.shulID) {
                initialShul = shulList.find(shul => shul.AppealsShulYearID === props.shulID)
            }else{
                initialShul = shulList.find(shul => shul.Default === true)
                if(initialShul){
                    props.onChange(initialShul.AppealsShulYearID)
                }else{
                    props.onChange(shulList[0].AppealsShulYearID)
                }
            }
            const shuls = shulList.map(shul => {
                    return {value: shul.AppealsShulYearID, name: shul.Name}
            })
            setShuls(shuls)
        }
    }, [shulList, props.shulID])

    //#endregion

    //#region event handlers
    const changeShulHandler =(id) => {
        props.onChange(id)
        props.onChangeInput()
    }

    //#endregion
    
    return (
        <Fragment>
        {shuls && shuls.length !== 0 && <CustomSelect options={shuls} label='Shul' onChange={changeShulHandler} className={props.className} selectedValue={props.shulID}/>}
        </Fragment>
    )
}

export default React.memo(ShulsList)