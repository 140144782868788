import { uiActions } from './ui-slice';
import store from './index'
import { authActions } from './auth-slice';
import { locationsActions } from './locations-slice';

export const fetchLocationsList = () => {
    return async (dispatch) => {
        const fetchData = async () => {
            dispatch(locationsActions.setIsLoading(true))
            var bearer = 'Bearer ' + localStorage.getItem('token');
            const url = store.getState().auth.url
            const response = await fetch(url + 'api/locations',{
                headers:{
                    'Authorization': bearer,
                }
            });

            if (!response.ok) {
                if(response.status === 401){ dispatch(authActions.logout()) }
                else{ throw new Error('Could not fetch locations list.') }
            }
            const data = await response.json()
            return data;
        };

        try {
            const locationsData = await fetchData()
            dispatch(locationsActions.loadLocations({ locations: locationsData || [] }))

        } catch (error) {
            dispatch(uiActions.showErrorMessage(error.message))
        }
        finally {
            dispatch(locationsActions.setIsLoading(false))

        }
    };
};

