import { configureStore } from "@reduxjs/toolkit";

import campaignsSlice from "./campaigns-slice";
import donorsSlice from "./donors-slice";
import organizationsSlice from "./organizations-slice";
import paymentsSlice from './payments-slice'
import solicitorsSlice from './solicitors-slice.js'
import paymentTypesSlice from './paymentTypes-slice'
import uiSlice from './ui-slice'
import tendersSlice from './tenders-slice'
import accountsSlice from './accounts-slice'
import filtersSlice from "./filters-slice";
import authSlice from "./auth-slice";
import shulsSlice from "./shuls-slice";
import pledgesSlice from "./pledges-slice";
import settingsSlice from "./settings-slice";
import statementsSlice from "./statements-slice";
import studentsSlice from "./students-slice";
import hubSlice from "./hub-slice.js";
import locationsSlice from "./locations-slice.js";

const store = configureStore({
    reducer: {
        ui: uiSlice.reducer,
        payments: paymentsSlice.reducer,
        campaigns: campaignsSlice.reducer,
        organizations: organizationsSlice.reducer,
        locations: locationsSlice.reducer,
        donors: donorsSlice.reducer,
        pledges: pledgesSlice.reducer,
        solicitors: solicitorsSlice.reducer,
        paymentTypes: paymentTypesSlice.reducer,
        tenders: tendersSlice.reducer,
        accounts: accountsSlice.reducer,
        filters: filtersSlice.reducer,
        auth: authSlice.reducer,
        shuls: shulsSlice.reducer,
        settings: settingsSlice.reducer,
        statements: statementsSlice.reducer,
        students: studentsSlice.reducer,
        hub: hubSlice.reducer
        //when you add a slice make sure to reset the state in useResetState hook
    }
});


export default store;