export const formatDate = (date) => {
    const d = new Date(date)

    const yyyy = d.getFullYear();
    let mm = d.getMonth() + 1; // Months start at 0!
    let dd = d.getDate();

    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;

    const formatted = mm + '/' + dd + '/' + yyyy;
    return formatted
}

export const formatDateWithDayName = (date) => {
    const d = new Date(date)

    const day = d.toLocaleString('en-us', {  weekday: 'long' });

    const yyyy = d.getFullYear();
    let mm = d.getMonth() + 1; // Months start at 0!
    let dd = d.getDate();

    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;

    const formatted = mm + '/' + dd + '/' + yyyy;
        return day + ', ' + formatted
}

export const formatDateWithTime = (date) => {
    const d = new Date(date)

    const yyyy = d.getFullYear();
    let mm = d.getMonth() + 1; // Months start at 0!
    let dd = d.getDate();

    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;

    let hh = d.getHours()
    if(hh < 10) hh = '0' + hh
    let m = d.getMilliseconds()
    if(m < 10) m = '0' + m
    let s = d.getSeconds()
    if(s < 10) s = '0' + s
    const formatted = mm + '/' + dd + '/' + yyyy + '  ' + hh + ':' + m + ':' + s;
    return formatted
}