import { useState } from 'react'
import Input from './Input'
import { useEffect } from 'react'

const DateInput = (props) => {
    const [date, setDate] = useState(props.value)

    useEffect(()=>{
        setDate(props.value)
    },[props.value])

    const changeInputHandler = (v)=> {
        setDate(v)
    }
    const blurInputHandler = (e) => {
        props.onChange(e.target.value)
    }
    return (
        <Input type={props.datetime ? 'datetime-local' : 'date'}
            label={props.label}
            required={props.required}
            inputClassName={props.inputClassName}
            className={props.className}
            placeholder={props.placeholder}
            onChange={changeInputHandler}
            value={date}
            onBlur={blurInputHandler}
            min={props.min}
            disabled={props.disabled}
            max='9999-12-31'
            readOnly={props.readOnly}
            autoFocus={props.autoFocus}
            children={props.children}
            invalid={props.invalid}
        />
    )
}

export default DateInput