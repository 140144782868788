import { CurrentDateForDateTimeInput } from "./datesForDateInput";

export const converDateForBackendWithCurrentTime = (date) => {
    var time = new Date(CurrentDateForDateTimeInput())
    var hour = time.getHours();  
    hour =  ('0'+hour.toString()).slice(-2);   //get it into two digits
    var minute = time.getMinutes(); 
    minute = ('0'+minute.toString()).slice(-2); //get it into two digits
    var convertedDate =   date + 'T' + hour + ':' + minute
    return convertedDate

}