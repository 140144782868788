import { Fragment } from 'react'

import classes from './DetailsItem.module.css'
import ArrowRight from '../../../images/Arrow - Right 2.svg'
import Arrowup from '../../../images/Arrow - Up 2-G.svg'
import ButtonMOut from '../Buttons/Buttons-Outline/ButtonMOut'
import AddButton from '../Buttons/AddButton'

const DetailsItem = (props) => {

    return (
        <Fragment>
            {props.open !== true && <div className={classes['detail-item']} onClick={props.onClick}>
            <img src={ArrowRight} alt=''/>
            <h3 className={classes['title-closed']}>{props.title}</h3>
            {props.add && <AddButton border={true} onClick={props.clickAdd} className={classes['btn-update']}>{props.add}</AddButton>}
            {props.changed && <ButtonMOut onClick={(e) => {props.onUpdate(e)}} className={classes['btn-update']} loading={props.loading}>Update</ButtonMOut>}
        </div>}
        <div className={`${classes['detail-item__open']} ${props.open === true ? '' : classes['closed']}`}>
            <div className={classes['detail-item__open-header']} onClick={props.onClose}>
                <img src={Arrowup} alt=''/>
            <h3 className={classes['title-closed']}>{props.title}</h3>
            {props.add && <AddButton border={true} onClick={props.clickAdd} className={classes['btn-update']}>{props.add}</AddButton>}
            {props.changed && <ButtonMOut onClick={(e) => {props.onUpdate(e)}} className={classes['btn-update']} loading={props.loading}>Update</ButtonMOut>}
            </div>
            <div className={`${classes['detail-item__body']} ${props.className}`}>{props.children}</div>
            </div>
        </Fragment>
    )
}

export default DetailsItem