import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    campaigns: [],
    subCampaigns: [],
    subCampaignRequired: false,
    isLoading: false,
}
const campaignsSlice = createSlice({
    name: "campaigns",
    initialState,
    reducers: {
        loadCampaigns(state, action) {
            state.campaigns = action.payload.campaigns;
        },
        loadSubCampaigns(state, action) {
            state.subCampaigns = action.payload.subCampaigns;
            if(action.payload.subCampaigns.length > 0){
                state.subCampaignRequired = action.payload.subCampaigns[0].Required
            }
        },
        setIsLoading(state, action) {
            state.isLoading = action.payload
        },
        resetState:() => initialState,
    },
});

export const campaignsActions = campaignsSlice.actions;

export default campaignsSlice;
