import { useEffect, useRef, useState } from "react"
import TasksList from "../../../UI/Hub/TasksList"
import TaskListTotals from "../../../UI/Hub/TaskListTotals"
import TaskList from "./TaskList"
import TaskListItem from "./TaskListItem"
import classes from './Dashboard.module.css'
import { toDecimalWithCommas } from "../../../../functions/toDecimal"
import arrow from '../../../../images/Arrow - Down small.svg'
import listClasses from './TaskListItem.module.css'
import { useReactToPrint } from "react-to-print"
import { useSelector } from "react-redux"
import Arrow from '../../../../images/Arrow - Up 2.svg'



const Dashboard = (props) => {
    const tasksRef = useRef();
    const handlePrint = useReactToPrint({
      content: () => tasksRef.current,
    });

    const loadedAll = useSelector((state) => state.hub.loadedAllDashboards)
    const loadedSettings = useSelector((state) => state.hub.loadedSettings)

    const [totals, setTotals] = useState([])
    const [opened, setOpened] = useState(true)
    const [amountDisplayed, setAmountDisplayed] = useState(10)
    const [showList, setShowList] = useState(false)


    
    useEffect(()=>{
        if(props.dashboard.Totals){
            let totals = Object.keys(props.dashboard.Totals).map((key, index) => {
            return {
                name: key,
                value: key === 'Balance' ? '$' + toDecimalWithCommas(props.dashboard.Tasks.reduce((a, b) => a + (b.Balance || 0), 0)) : key === 'Total Tasks' ? props.dashboard.Tasks.length : props.dashboard.Totals[key]
            }
            })
            setTotals(totals)
        }
        if(!props.dashboard.AdminDashboard && props.dashboard.Settings.ShowTasksByDefault && props.dashboard.Settings.ShowTasksByDefault === "True"){
            setShowList(true)
        }
    },[props.dashboard])

        
    useEffect(()=>{
        if(loadedSettings){
            if(props.dashboard.Settings.NumberOfTasksToLoad && parseInt(props.dashboard.Settings.NumberOfTasksToLoad) > 0){
                if(props.dashboard.Tasks.length >= parseInt(props.dashboard.Settings.NumberOfTasksToLoad)){
                    setAmountDisplayed(parseInt(props.dashboard.Settings.NumberOfTasksToLoad))
                }
                else{
                    setAmountDisplayed(props.dashboard.Tasks.length)
                }
            }
            else{
                if(props.dashboard.Tasks.length >= 10){
                    setAmountDisplayed(10)
                }
                else{
                    setAmountDisplayed(props.dashboard.Tasks.length)
                }
            }
        }
    },[props.dashboard.Tasks.length, props.dashboard.Settings, loadedSettings])

    const showMoreHandler = () => {
        if(loadedAll > 0){
           const amountToLoad =  props.dashboard.Settings.NumberOfTasksToLoad && parseInt(props.dashboard.Settings.NumberOfTasksToLoad) > 0 ? parseInt(props.dashboard.Settings.NumberOfTasksToLoad) : 10
           if(amountDisplayed + amountToLoad > props.dashboard.Tasks.length){
               setAmountDisplayed(props.dashboard.Tasks.length)
           }
           else{
               setAmountDisplayed(prevState => prevState + amountToLoad)
           }
        }
        
    }

    const showAllHandler = () => {
        setAmountDisplayed(props.dashboard.Tasks.length)
    }
    
return <div ref={tasksRef}>{loadedSettings && <TasksList title={props.dashboard.Caption} 
            open={opened} onClick={() => setOpened(prevState => !prevState)} 
            total={loadedAll ? props.dashboard.NumberOfTasksForCompleted : 0}
            completed={loadedAll ? props.dashboard.CompletedTasks : 0}
            actions={props.dashboard.Settings.DashboardActions}
            dashboardSettingId={props.dashboard.DashboardSettingID}
            onPrint={handlePrint}>
                <div className={classes.sticky}>
                    <TaskListTotals totals={totals} balance={props.dashboard.Tasks.map(t => t.Balance)}/>
                    {props.dashboard.Settings.ShowHeadersOnGrid && props.dashboard.Settings.ShowHeadersOnGrid === "True" && <div className={`${listClasses.task} ${props.dashboard.AdminDashboard ? listClasses['headers-admin'] : listClasses.headers}`}>
                    <div className={listClasses.details}>
                    {props.dashboard.Tasks.length > 0 && props.dashboard.Tasks[0].FieldsToDisplay.length > 0 && props.dashboard.Tasks[0].FieldsToDisplay.map((f, i) =>{
                                return <p className={listClasses.detail} key={i}>{f.FieldName && f.FieldName.replace(/([a-z])([A-Z])/g, '$1 $2')}</p>
                            } )}
                        </div></div>}
                        <div className={classes.header}>
            <div className={classes.line}/>
            <p className={classes.view} onClick={() => setShowList(prevState => !prevState)}>View Tasks ({amountDisplayed})<img alt='' src={Arrow}/></p>
            <div className={classes.line}/>
        </div></div>
    <TaskList name={props.dashboard.DashboardSettingID}  adminDashboard={props.dashboard.AdminDashboard}> 
    {showList && <div className={classes.list}>
        {props.dashboard.Tasks.slice(0, amountDisplayed).map(t => {
            return <TaskListItem cancelco={props.cancelco} key={Math.random()} task={t} dashboardSettingId={props.dashboard.DashboardSettingID} settings={props.dashboard.Settings} adminDashboard={props.dashboard.AdminDashboard}/>
        })}
        </div>}
        {showList && props.dashboard.Tasks.length > amountDisplayed && loadedAll > 0 && <div className={classes.load}>
        <p className={classes['show-more']} onClick={showMoreHandler}>Load more<img src={arrow} alt=''/></p>
        <p className={classes['show-more']} onClick={showAllHandler}>Load all<img src={arrow} alt=''/></p>
        </div>}
    </TaskList>
</TasksList>}
</div>
}

export default Dashboard