import { useEffect, useState } from "react"
import useHttp from "../../../../hooks/use-http"
import PopupList from "../../../UI/Hub/PopupList"
import PopupListItem from "../../../UI/Hub/PopupListItem"
import { useDispatch, useSelector } from "react-redux"
import { uiActions } from "../../../../store/ui-slice"
import { fetchDashboards } from "../../../../store/hub-actions"
import { hubActions } from "../../../../store/hub-slice"


const UsersListPopup = (props) => {
    const {sendRequest: getUsers} = useHttp()
    const {sendRequest: assignTask, isLoading: assignTaskLoading} = useHttp()
    const dispatch = useDispatch()

    const users = useSelector((state) => state.hub.users)


    useEffect(()=>{
        if(users.length === 0){
            fetchUsersList()
        }
    },[users])
    
    const fetchUsersList = () => {
        getUsers({url:'api/getUsers'}, handleGetUsersResponse)
    } 

    const handleGetUsersResponse = (data) => {
        dispatch(hubActions.loadUsers(data))
    }

    const assignTaskHandler = (e, id) =>{
        e.stopPropagation(); 
        assignTask({url:`api/assignTask?dashboardId=${props.dashboardId}&id=${props.id}&reminderId=${props.reminderId}&userId=${id}`}, (data) => handleAssignTaskResponse(data, id))
    }

    const handleAssignTaskResponse = (data, id) => {
        props.onClose();
        if(data === ''){
            dispatch(uiActions.showSidePopup({status: 'success', header: 'Successfuly assigned task'}))
            //if assigned to a different user it should remove from list till the page reloads
            const userName = localStorage.getItem('userName')
            const user = users.find(u => u.UserID === id)
            if(user && userName && user.UserName !== userName){
                dispatch(hubActions.removeTaskFromList({dashboardId: props.dashboardId, ID: props.id}))
            }
            props.cancelco.abort()
            dispatch(fetchDashboards(true))
        }
        else{
            dispatch(uiActions.showSidePopup({status: 'error', header: 'Error assigning task', message: data}))
        }

    }

    return (
             <PopupList isLoading={assignTaskLoading}>
                {users.map((u, i) => {
                return <PopupListItem onClick={(e) => !assignTaskLoading && assignTaskHandler(e, u.UserID)} second={(i + 1) % 2 === 0}>
                        {u.UserName}
                </PopupListItem>
                })}
    </PopupList>
    )
}

export default UsersListPopup