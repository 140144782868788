import classes from './TaskList.module.css'
import { useState } from 'react'
import AddButton from '../../../UI/Buttons/AddButton'
import { useNavigate } from 'react-router-dom'

const TaskList = (props) => {
    const navigate = useNavigate()

    const addTaskHandler = () => {
        navigate(`new-task?dashboard=${props.name}`)
    }

    return <div className={classes.list}>
        {props.children}
        {!props.adminDashboard && <div className={classes.footer}>
            <AddButton green onClick={addTaskHandler}>New Task</AddButton>
        </div>}
    </div>
}

export default TaskList