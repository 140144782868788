import { Fragment, useEffect, useState } from "react"
import ModalDetails from "../../UI/Modals/ModalDetails"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import DetailsItem from "../../UI/Others/DetailsItem"
import useHttp from "../../../hooks/use-http"
import { hubActions } from "../../../store/hub-slice"
import CustomSelect from "../../UI/Dropdowns/CustomSelect"
import Checkbox from "../../UI/Checkboxs/Checkbox"
import Input from "../../UI/Inputs/Input"
import DateInput from "../../UI/Inputs/DateInput"
import ChangesMessageBox from "../../UI/MessageBoxs/ChangesMessageBox"
import { fetchReminderNames, fetchReminderTypes } from "../../../store/hub-actions"
import CustomSelectFreeText from "../../UI/Dropdowns/CustomSelectFreeText"
import classes from './ReminderDetails.module.css'
import { formatDate, formatDateWithTime } from "../../../functions/Dates/formatDate"
import TextField from "../../UI/Inputs/TextField"
import { uiActions } from "../../../store/ui-slice"
import { getUpdateMessageBody } from "../../../functions/getMessageBody"
import ShulsList from "../../Payments/PaymentsDropdowns/ShulsList"

const REMINDER_STATUSES = [{value: 0, name: 'Open'}, {value: 1, name: 'Complete'}, {value: 2, name: 'Canceled'}]

const ReminderDetails = () => {

    const donorReminders = useSelector((state) => state.donors.donorReminders)
    const usersList = useSelector((state) => state.hub.users)
    const campaignsList = useSelector((state) => state.campaigns.campaigns)
    const types = useSelector((state) => state.hub.reminderTypes)
    const names = useSelector((state) => state.hub.reminderNames)

    const params = useParams()
    const {sendRequest: updateReminder, isLoading } = useHttp()
    const {sendRequest: getUsers } = useHttp()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [reminder, setReminder] = useState()
    const [formChanged, setFormChanged] = useState()
    const [showMessage, setShowMessage] = useState()
    const [users, setUsers] = useState([])
    const [campaigns, setCampaigns] = useState([])

    useEffect(()=>{
        if(params.reminderId && donorReminders.length > 0){
            let r = donorReminders.find(reminder => reminder.ReminderID === parseInt(params.reminderId))
            if(r){
                setReminder(r)
                console.log(r)
            }
        }
    },[donorReminders, params])

    useEffect(()=>{
        if(usersList.length === 0){
            fetchUsersList()
        }
        else{
            let users  = usersList.map(u => {
                return {value: u.UserID, name: u.UserName}
            })
            setUsers(users)
        }
    },[usersList])

    useEffect(()=>{
        if(types.length === 0){
            dispatch(fetchReminderTypes())
        }
        if(names.length === 0){ 
            dispatch(fetchReminderNames())
        }
    },[])

    useEffect(()=>{
        let campaigns = campaignsList.map(campaign => {
            return {value: campaign.CampaignID, name: campaign.Name}
        })
        campaigns = [{ value: 0, name: '-'}, ...campaigns]
        setCampaigns(campaigns)
    },[campaignsList])

    const fetchUsersList = () => {
        getUsers({url:'api/getUsers'}, handleGetUsersResponse)
    } 

    const handleGetUsersResponse = (data) => {
        dispatch(hubActions.loadUsers(data))
    }

    
    const showMessageHandler = () => {
        if(formChanged){
            setShowMessage(true)
        }
        else{
            closeDetailsHandler()
        }
    }

    const updateReminderHandler = () => {
        updateReminder({url: '/api/reminders', method: 'POST', body: reminder, headers: { 'Accept': 'application/json', 'content-type': 'application/json' }}, handleUpdateResponse)
    }

    const handleUpdateResponse = (data) => {
        if(data === ''){
            dispatch(uiActions.showSidePopup({status: 'success', header: getUpdateMessageBody('reminder')}))
            if(showMessage){
                closeDetailsHandler()
            }
            else{
                setFormChanged(false)
            }
        }
        else{
            dispatch(uiActions.showSidePopup({status: 'error', header: getUpdateMessageBody('reminder', false), message: data}))
        }
    }

    const closeDetailsHandler = () => {
        navigate(-1)
    }
 
    const changeUserHandler = (id) => {
        setReminder(prevState => ({...prevState, ForUser: id}))
        setFormChanged(true)
    }

    const changeCampaignHandler = (id) => {
        setReminder(prevState => ({...prevState, CampaignID: id}))
        setFormChanged(true)
    }

    const changeDescriptionHandler = (value) => {
        setReminder(prevState => ({...prevState, Description: value}))
        setFormChanged(true)
    }

    const changeStatusHandler = (id) => {
        setReminder(prevState => ({...prevState, Status: id}))
        setFormChanged(true)
    }

    const changeSendEmailHandler = () => {
        setReminder(prevState => ({...prevState, SendEmail: !prevState.SendEmail}))
        setFormChanged(true)
    }

    const changeIsFinancialHandler = () => {
        setReminder(prevState => ({...prevState, IsFinancial: !prevState.IsFinancial}))
        setFormChanged(true)
    }

    const changeDateHandler = (value) => {
        setReminder(prevState => ({...prevState, Date: value}))
        setFormChanged(true)
    }

    const changeTypeHandler = (value) => {
        setReminder(prevState => ({...prevState, Type: value}))
        setFormChanged(true)
    }

    const changeNameHandler = (value) => {
        setReminder(prevState => ({...prevState, Name: value}))
        setFormChanged(true)
    }

    const changeShulHandler = (value) => {
        setReminder(prevState => ({...prevState, ShulID: value}))
    }


    return (
        <Fragment>{reminder && 
            <ModalDetails title='Reminder' id={reminder.ReminderID} onClose={showMessageHandler}>
                <DetailsItem className={classes.details} title='Reminder Info' onClick={() => {}} open={true} onClose={() => {}} changed={formChanged} onUpdate={updateReminderHandler} loading={isLoading}>
                    <div className={classes['first-row']}><p className={classes.id}>Donor ID: {reminder.ID}</p>
                    <p className={classes['add-date']}>{formatDateWithTime(reminder.AddDate)}</p></div>
                    <div className={classes.fields}><CustomSelectFreeText options={names} label='Name' onChange={changeNameHandler} className={classes.name} selectedValue={reminder.Name}/>
                    <CustomSelectFreeText selectedValue={reminder.Type} options={types} label='Type' onChange={changeTypeHandler} />
                    <DateInput label='Date' datetime value={reminder.Date} onChange={changeDateHandler}/>
                    <TextField label='Description' value={reminder.Description} onChange={changeDescriptionHandler}/>
                    <Checkbox id='sendEmail' label='Send Email' checked={reminder.SendEmail} onChange={changeSendEmailHandler}/>
                    <Checkbox id='isFinancial' label='Financial Reminder' checked={reminder.IsFinancial} onChange={changeIsFinancialHandler}/>
                    <CustomSelect options={users} label='For User' onChange={changeUserHandler} className='' selectedValue={reminder.ForUser}/>
                    <CustomSelect options={REMINDER_STATUSES} label='Status' onChange={changeStatusHandler} className='' selectedValue={reminder.Status}/>
                    <CustomSelect options={campaigns} label='Campaign' onChange={changeCampaignHandler} className='' selectedValue={reminder.CampaignID}/>
                    <ShulsList onChange={changeShulHandler} onChangeInput={()=> setFormChanged(true)} shulID={reminder.ShulID}/>
                    </div></DetailsItem>
            </ModalDetails>}
            {showMessage && <ChangesMessageBox onSave={updateReminderHandler} onNoSave={() => closeDetailsHandler(true)} onCancel={() => setShowMessage(false)} />}
        </Fragment>
    )
}

export default ReminderDetails