
const CurrentDateForDateInput = () => {
    var date = new Date();

var day = ("0" + date.getDate()).slice(-2);
var month = ("0" + (date.getMonth() + 1)).slice(-2);

var today = date.getFullYear() + "-" + (month) + "-" + (day);
return today;
}

export const FormatDateForDateInput = (date) => {
    var d = new Date(date);

    var day = ("0" + d.getDate()).slice(-2);
    var month = ("0" + (d.getMonth() + 1)).slice(-2);
    
    var today = d.getFullYear() + "-" + (month) + "-" + (day);
    return today;
    
}

export const CurrentDateForDateTimeInput = () => {
    var now = new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000).toISOString().split('Z')[0]
    now = now.slice(0, now.lastIndexOf(":"))
return now;
}
export default CurrentDateForDateInput