import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    donors: [],
    loadedDonors: false,
    addresses: [],
    contacts: [],
    phoneHomes: [],
    phoneMobiles: [],
    emails: [],
    selectedDonor: {},
    isLoading: false,
    donorsForFilter: [],
    filteredDonorsList: [],
    donorReminders: []
}

const donorsSlice = createSlice({
    name: "donors",
    initialState,
    reducers: {
        loadDonors(state, action) {
            state.loadedDonors = true
            const results = action.payload.donors.filter(({ DonorsID: id1 }) => !state.donors.some(({ DonorsID: id2 }) => id2 === id1));
            if (results.length > 0 && state.donors.length > 0) {
              //  state.selectedDonor = results[0]
            }
            else  {
                //const sdonor = action.payload.donors.find(donor => donor.DonorsID === state.selectedDonor.DonorsID)
                //if (sdonor) {
                 //   state.selectedDonor = sdonor
               // }
            }
            state.donors = action.payload.donors;
            var donors = action.payload.donors.map(donor => {
                var value = donor.FirstName + ' ' + donor.LastName
                return {id: donor.DonorsID, label: value}
            })
            state.donorsForFilter = donors
            state.filteredDonorsList = action.payload.donors
        },
        loadDonor(state, action){
            if(action.payload.donors.length === 1){
                const donorsList = state.donors
                state.donors = donorsList.map(d => d.DonorsID === action.payload.donors[0].DonorsID ? action.payload.donors[0] : d);
                state.filteredDonorsList = state.filteredDonorsList.map(d => d.DonorsID === action.payload.donors[0].DonorsID ? action.payload.donors[0] : d);
            }
        },
        setDonorsFilteredList(state, action){
            state.filteredDonorsList = action.payload
        },
        resetDonorsFilteredList(state){
            state.filteredDonorsList = state.donors
        },
        setIsLoading(state, action) {
            state.isLoading = action.payload
        },
        setSelectedDonor(state, action) {
        if (action.payload) {
              const id = action.payload
                const selectedDonor = state.donors.find(donor => donor[Object.keys(donor)[0]] === id)
          if (selectedDonor) {
            state.selectedDonor = selectedDonor
         }
         }
         },
        loadDonorsDetails(state, action) {
            state.selectedDonor = action.payload.donor
        },
        updateDonorsList(state, action) {
            const changedDonorIndex = state.donors.findIndex((donor => donor.DonorsID === action.payload.Donor.DonorsID));
            if (changedDonorIndex > 0) {
                state.donors[changedDonorIndex] = action.payload.Donor
            }
            else {
                state.donors = [...state.donors, action.payload.Donor]
            }
            state.selectedDonor = action.payload.Donor
        },
        loadAddresses(state, action) {
            state.addresses = action.payload.addresses;

        },
        loadContacts(state, action) {
            state.contacts = action.payload.contacts
            const emails = action.payload.contacts.filter(contact => contact.Type === 3)
            if(emails) state.emails = emails

            const phoneHomes = action.payload.contacts.filter(contact => contact.Type === 0)
            if(phoneHomes) state.phoneHomes = phoneHomes

            const phoneMobiles = action.payload.contacts.filter(contact => contact.Type === 2)
            if(phoneMobiles) state.phoneMobiles = phoneMobiles
        },
        loadDonorReminders(state, action){
            state.donorReminders = action.payload
        },
        resetAddressesList(state) {
            state.addresses = []
        },
        resetEmailsList(state) {
            state.emails = []
        },
        resetSelectedDonor(state) {
            state.selectedDonor = {}
            state.donorReminders = []
        },
        resetDonorsInfo(state) {
            state.selectedDonor = {}
            state.addresses = []
            state.contacts = []
            state.emails = []
            state.phoneHomes = []
            state.phoneMobiles = []
            state.donorReminders = []
        },
        resetState:() => initialState,
    },
});

export const donorsActions = donorsSlice.actions;

export default donorsSlice;
